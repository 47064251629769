import React from "react"
import ApplyIcon from "../images/apply-icon.png"
import css from "@emotion/css"
import phoneIcon from "../images/phone-icon.png"
import mailIcon from "../images/mail-icon.png"
import chatIcon from "../images/chat-icon.png"
import Form from "../components/Form"

import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
export default function Apply({ data: { page } }) {
  const options = {
    renderNode: {
      "embedded-entry-block": (node, children) => (
        <li className="flex flex-col sm:block sm:pl-16 relative mb-6">
          <div className="sm:absolute top-0  w-12 left-0 h-auto">
            {" "}
            <img
              alt=""
              src={
                node.data.target.fields.icon["en-US"].fields.file["en-US"].url
              }
            ></img>
          </div>
          <div>
            {" "}
            <h3 className="font-semibold text-2xl">
              {node.data.target.fields.title["en-US"]}
            </h3>
            <p className="font-semibold text-brand-1">
              {node.data.target.fields.text["en-US"]}
            </p>
          </div>
        </li>
      ),
    },
  }

  return (
    <div className="bg-brand-indigo">
      <div className="container py-10 flex flex-col md:flex-row ">
        <div>
          <h2 className="text-blue-800 font-bold text-5xl leading-tight mb-12">
            {page.title}
          </h2>
          <ul className="pl-4 md:pl-12">
            {documentToReactComponents(page.content.json, options)}
            {false &&
              Array.from({ length: 5 }).map((it, i) => (
                <li
                  className="flex flex-col sm:block sm:pl-16 relative mb-6"
                  key={i}
                >
                  <div className="sm:absolute top-0  w-12 left-0 h-auto">
                    {" "}
                    <img alt="" src={ApplyIcon}></img>
                  </div>
                  <div>
                    {" "}
                    <h3 className="font-semibold text-2xl">
                      Online application process
                    </h3>
                    <p className="font-semibold text-brand-1">
                      Complete application and upload your documents through our
                      secure customer portal.
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="w-full lg:w-2/3 md:pl-10">
          <Form></Form>
        </div>
      </div>
      <div className="mt-10 md:flex-row flex-col flex items-center justify-center bg-blue-100 shadow border border-blue-200 py-6 md:p-8">
        <div className="my-1 mx-6 relative pl-4 ">
          <img
            className="absolute  "
            css={css`
              top: 0.45rem;
              left: 0rem;
            `}
            src={chatIcon}
          ></img>{" "}
          Live Chat
        </div>
        <div className="my-1 mx-6 relative pl-4">
          <img
            className="absolute  "
            css={css`
              top: 0.45rem;
              left: 0rem;
            `}
            src={phoneIcon}
          ></img>{" "}
          Call Us: <a href="tel:2122573939">(212) 257-3939</a>
        </div>
        <div className="my-1 mx-6 relative pl-4">
          {" "}
          <img
            className="absolute  "
            css={css`
              top: 0.45rem;
              left: 0rem;
            `}
            src={mailIcon}
          ></img>
          Email: <a href="mailto:sales@fundingna.com">sales@fundingna.com</a>
        </div>
      </div>
      <div className="bg-gray-700 text-white">
        <div className="container py-8">
          <h2 className="text-center mb-4 text-gray-500 text-xl">
            Partners Featured In
          </h2>
          <div className="flex md:flex-row flex-col flex-wrap items-center justify-center">
            {page.partners.map((icon, i) => (
              <div className=" last:mb-3 mb-8 mx-6 md:mb-4" key={i}>
                <img alt="" src={icon.file.url}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  {
    page: contentfulApplyNowPage {
      title
      seo {
        title
        description
      }
      partners {
        file {
          url
        }
      }
      content {
        json
      }
    }
  }
`
